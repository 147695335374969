var setHeders, token = ""

setHeders = function(contentType, useToken) {
  var header;

  switch (contentType) {
  case "json":
      if (token !== undefined && token.length > 0) {
          header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
      } else {
          if (useToken !== null && useToken !== undefined) {
            header = {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${useToken}`
            }
          } else {
            header = {
              'Content-Type': 'application/json'
            }
          }
      }
    break;

  case "blob":
    if (token !== undefined && token.length > 0) {
          header = {
            'Content-Type': 'application/octet-stream',
            'Authorization': `Bearer ${token}`
          }
      } else {
          if (useToken !== null && useToken !== undefined) {
            header = {
              'Content-Type': 'application/octet-stream',
              'Authorization': `Bearer ${useToken}`
            }
          } else {
            header = {
              'Content-Type': 'application/octet-stream'
            }
          }
      }
    break;

  case "text":
    if (token !== undefined && token.length > 0) {
          header = {
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`
          }
      } else {
        if (useToken !== null && useToken !== undefined) {
          header = {
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${useToken}`
          }
        } else {
          header = {
            'Content-Type': 'text/plain'
          }
        }
      }
    break;

  default:
    if (token !== undefined && token.length > 0) {
          header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
      } else {
        if (useToken !== null && useToken !== undefined) {
          header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${useToken}`
          }
        } else {
          header = {
            'Content-Type': 'application/json'
          }
        }
      }
    break;
  }
  return header
}

export const connectionStatus = (ucc, cloud, mobileDevice, jwt, mobileDsMode) => (dispatch) => {
    var readyStateChanged, readyStateChangedUCC, baseUrl, url, uccUrl, xhrUCC, xhr, reponseStatus;

    uccUrl = ucc + "/api/v1"
    url = cloud + "/api/v1"

    xhrUCC = new XMLHttpRequest();
    xhr = new XMLHttpRequest();

    xhrUCC.open("GET", uccUrl + "/status");
    xhrUCC.setRequestHeader("Content-type", "application/json");

    xhr.open("GET", url + "/status");
    xhr.setRequestHeader("Content-type", "application/json");

    if (jwt !== undefined && jwt.length > 0) {
      token = jwt;
      // xhrUCC.setRequestHeader('Authorization', `Bearer ${token}`);
      // xhrUCC.withCredential = true;

      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.withCredential = true;
    }

    readyStateChangedUCC = function () {
      if (!mobileDevice) {
        if (xhrUCC.readyState === 4) {
            if (xhrUCC.status === 200) {
                reponseStatus = true;
                if (mobileDsMode) {
                  baseUrl = url;
                  token = jwt;
                } else {
                  token = "";
                  baseUrl = uccUrl;
                }
                dispatch({
                  type: 'CONNECTION_STATUS',
                  payload: {reponseStatus, baseUrl}
                })
            } else {
                xhr.send()
            }
        }
      }
    };

    readyStateChanged = function () {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                reponseStatus = true;
                baseUrl = url
            } else {
                baseUrl = null
                reponseStatus = false;
            }
            dispatch({
              type: 'CONNECTION_STATUS',
              payload: {reponseStatus, baseUrl}
            })
        }
    };

    readyStateChangedUCC = readyStateChangedUCC.bind(this);
    readyStateChanged = readyStateChanged.bind(this);

    xhrUCC.onreadystatechange = readyStateChangedUCC;
    xhr.onreadystatechange = readyStateChanged;

    if (!mobileDevice) {
      xhrUCC.send();
    } else {
      xhr.send()
    }
}

export const changeUrl = (ucc, cloud, mobileDevice, jwt, mobileDsMode) => (dispatch) => {
    var uccUrl, url, baseUrl, reponseStatus = true;

    uccUrl = ucc + "/api/v1"
    url = cloud + "/api/v1"

    if (jwt !== undefined && jwt.length > 0) {
      token = jwt;
    }

    if (mobileDsMode) {
      baseUrl = url;
      token = jwt;
    } else {
      token = "";
      baseUrl = uccUrl;
    }
    dispatch({
      type: 'CONNECTION_STATUS',
      payload: {reponseStatus, baseUrl}
    })

    return true;
}

export const uccConnectionStatus = (url, token) => (dispatch) => {

    return fetch(url + "/api/v1/status", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        if(response.ok) {
          return true
        }
      }).catch(function() {
          return false
      })
}

export const cloudConnectionStatus = (url, token) => (dispatch) => {

    return fetch(url + "/api/v1/status", {
        method: 'GET',
        headers: {
          'Authorization' : 'Bearer ' + token
        }
      }).then(response => {
        if(response.ok) {
          return true
        }
      }).catch(function() {
          return false
      })
}

export const getURLs = () => (dispatch) => {

    return fetch(process.env.PUBLIC_URL + "/config.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getVersion = () => (dispatch) => {

    return fetch(process.env.PUBLIC_URL + "/version.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getJNLP = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/jnlp", {
        method: 'GET'
      }).then(response => {
        console.log(response.url)
        // response.text()
        return response.url
    });
}

export const getQR = (url, input, useToken) => (dispatch) => {
    var header = setHeders("json", useToken);

    var data = {
      "textData" : input,
      "width" : 0,
      "height" : 0,
      "imageFormat" : "PNG"
    }

    return fetch(url, {
        method: 'POST',
        headers: header,
        body:JSON.stringify(data)
    }).then((response) => {
        return response.blob();  
    });
}

export const getListMobileDSProviders = (url) => (dispatch) => {
    var requestUrl, header = setHeders("json");
        
    requestUrl = url + "/mssp"

    return fetch(requestUrl, {
        method: 'GET',
        headers: header,
        dataType: "json"
      }).then(response => {
        return response.json()     
    });
}

export const getOwnerProfilesList = (url, msspId, phoneNumber) => (dispatch) => {
    var requestUrl, header = setHeders("json")
        
    requestUrl = url + "/mssp/" + msspId + "/profiles";

    return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify({phoneNumber : phoneNumber})
      }).then(response => {
        return response.json()     
    });
}

export const convertToAttached = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/es.convert/type/attached", {
        method: 'PUT',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()
    });
}

export const convertToDetached = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/es.convert/type/detached", {
        method: 'PUT',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()
    });
}

export const vtcoLogin = (url, data) => (dispatch) => {
    var header = setHeders("json")

    return fetch(url, {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()     
    });
}

export const adLogin = (url, data) => (dispatch) => {
    var header = setHeders("json")

    return fetch(url, {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()     
    });
}

export const getVtcoTokenList = (url, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/token.list", {
        method: 'GET',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        return response.json()     
    });
}

export const getVtcoProtectedParams = (url, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/protectedParameters/settings", {
        method: 'GET',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        return response.json()     
    });
}

export const getVtcoTokenEnquire = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/token.enquire", {
        method: 'PUT',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()     
    });
}

export const createVtcoDs = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/es.create/data", {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()
    });
}

export const encryptVtco = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/encipherment", {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()
    });
}

export const decryptVtco = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/decipherment", {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()
    });
}

export const premission2fVtco = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/permission.2f", {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()
    });
}

export const premissionVtco = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/permission", {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      }).then(response => {
        if (response.ok) {
          return true;
        } else {
          return response.json()
        }
    });
}

export const depositsignLogin = (userName, password) => (dispatch) => {
    var header = setHeders("json")

    return fetch("https://depositsign.com/api/v1/test/auth/login", {
        method: 'POST',
        dataType: "json",
        headers: header,
        body: JSON.stringify({"UserName" : userName, "Password" : password})
      }).then(response => {
        return response.json()     
    });
}

export const depositsignGetCertInfo = (keyId, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch("https://depositsign.com/api/v1/test/keys/" + keyId + "/certificates/info", {
        method: 'GET',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        return response.json()     
    });
}

export const signHash = (hash, keyName, pass, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch("https://depositsign.com/api/v1/tet/sign/hash", {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        return response.json()     
    });
}

export const signFile = (fileData, keyName, pass, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch("https://depositsign.com/api/v1/tet/sign/file", {
        method: 'POST',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        return response.json()     
    });
}

export const createMobileDS = (url, uuid, msspId, data) => (dispatch) => {
    var requestUrl, header = setHeders("json")
        
    requestUrl = url + "/ticket/" + uuid + "/mssp/" + msspId + "/ds/creator";

    return fetch(requestUrl, {
        method: 'POST',
        dataType: "json",
        headers: header,
        body: JSON.stringify({phoneNumber : data.number, positionId : data.position, serviceId : data.service})
      }).then(response => {
        return response.json()     
    });
}

export const getCreateMobileDSResult = (url, uuid, msspId) => (dispatch) => {
    var requestUrl, header = setHeders("json")
        
    requestUrl = url + "/ticket/" + uuid + "/mssp/" + msspId + "/ds/creator";

    return fetch(requestUrl, {
        method: 'GET',
        headers: header,
        dataType: "json"
      }).then(response => {
        return response.json()     
    });
}

export const getMobileCertificate = (url, msspId, data) => (dispatch) => {
    var requestUrl, header = setHeders("json")
        
    requestUrl = url + "/mssp/" + msspId + "/certificate/base64";
    return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify({phoneNumber : data.number, positionId : data.position, serviceId : data.service})
      }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
          return null
        }   
    });
}

export const getMobileCertificateData = (url, msspId, data) => (dispatch) => {
    var requestUrl, header = setHeders("json")
        
    requestUrl = url + "/mssp/" + msspId + "/certificate/info";
    return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify({phoneNumber : data.number, positionId : data.position, serviceId : data.service})
      }).then(response => {
        if (response.ok) {
            return response.json();
        }
           
    });
}

export const getMobileDSData = (url, uuid, msspId) => (dispatch) => {
    var requestUrl, header = setHeders("blob")
        
    requestUrl = url + "/ticket/" + uuid + "/mssp/" + msspId + "/ds/data";

    return fetch(requestUrl, {
      method: 'GET',
      dataType: "blob",
      headers: header
    }).then((response) => {
      if(response.ok) {
        return response.blob()
      } else {
        return null
      }
    });
}

export const getMobileDSBase64Data = (url, uuid, msspId) => (dispatch) => {
    var requestUrl, header = setHeders("json");
        
    requestUrl = url + "/ticket/" + uuid + "/mssp/" + msspId + "/ds/base64Data";

    return fetch(requestUrl, {
      method: 'GET',
      dataType: "json",
      headers: header
    }).then((response) => {
      if(response.ok) {
        return response.json()
      } else {
        return null
      }
    });
}

export const createSession = (url, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)
        
    requestUrl = url + "/ticket"

    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        dataType: "json"
      }).then(response => {
        return response.json()     
    });
}

export const sendInputData = (url, uuid, inputData, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("blob", useToken)

    if (inputData === undefined) {
        return
    }
        
    requestUrl = url + "/ticket/" + uuid + "/data"

    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: inputData
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const sendBase64InputData = (url, uuid, inputData, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("blob", useToken)

    var data = {
        base64Data: inputData
    }
        
    requestUrl = url + "/ticket/" + uuid + "/data"

    if (inputData === null) {
      return
    }

    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data)
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const sendEncryptTextData = (url, uuid, inputData, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("blob", useToken)
        
    requestUrl = url + "/ticket/" + uuid + "/data"

    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: inputData
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const sendDSData = (url, uuid, inputDSData, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("blob", useToken)
        
    requestUrl = url + "/ticket/" + uuid + "/ds/data"
    
    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: inputDSData
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const sendBase64DSData = (url, uuid, inputDSData, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("blob", useToken)

    var data = {
        base64Data: inputDSData
    }
        
    requestUrl = url + "/ticket/" + uuid + "/ds/data"
    
    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data)
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const modifyDS = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("text", useToken)
        
    requestUrl = url + "/ticket/" + uuid + "/ds/modifier"
    
    return fetch(requestUrl, {
        method: 'POST',
        dataType: "json",
        headers: header
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const getModifyDSResult = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)
        
    requestUrl = url + "/ticket/" + uuid + "/ds/modifier"
    
    return fetch(requestUrl, {
        method: 'GET',
        headers: header,
        dataType: "json"
      }).then((response) => {
        return response.json()
    });
}

export const getModifiedDSBase64Data = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)
        
    requestUrl = url + "/ticket/" + uuid + "/ds/base64ModifiedData"
    
    return fetch(requestUrl, {
        method: 'GET',
        headers: header,
        dataType: "json"
      }).then((response) => {
        if(response.ok) {
          return response.json()
        } else {
          return null
        }
    });
}

export const getModifiedDSData = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)
        
    requestUrl = url + "/ticket/" + uuid + "/ds/modifiedData"
    
    return fetch(requestUrl, {
        method: 'GET',
        headers: header,
        dataType: "json"
      }).then((response) => {
        console.log(response);
        if(response.ok) {
          return response.blob()
        } else {
          return null
        }
    });
}

export const sendSessionOption = (url, uuid, options, useToken) => (dispatch) => {

    var selectedOptions, requestUrl, header = setHeders("json", useToken)
      selectedOptions = options

    requestUrl = url + "/ticket/" + uuid + "/option";
      return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify(selectedOptions)
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const getADUserInfo = (url, login, password) => (dispatch) => {
    var userPassword, requestUrl, header = setHeders("json")
      userPassword = {
        user: login,
        password: password
      }

    requestUrl = url;

    var timeout = function(ms, promise) {
      return new Promise(function(resolve, reject) {
        setTimeout(function() {
          reject(new Error("timeout"))
        }, ms)
        promise.then(resolve, reject)
      })
    }
    
    return timeout(10000, fetch(requestUrl, {
            method: 'PUT',
            dataType: "json",
            headers: header,
            body: JSON.stringify(userPassword)
        })).then((response) => {
        console.log(response)
        if (response.ok) {
            console.log(response);
            return response.json()
        } else {
            return null
        }
    }).catch(function(error) {
        console.log('Request timed out')
        return null
    })
}

export const openFileDialog = (url) => (dispatch) => {
    var requestUrl, header = setHeders("json")

    requestUrl = url + "/ui/keyContainerChooser";
      return fetch(requestUrl, {
        method: 'PUT',
        headers: header,
        dataType: "json"
      }).then((response) => {
        console.log(response)
        if (response.ok) {
            console.log(response);
            return response.json()
        } else {
            return null
        }
    });
}

export const sendTokenPath = (url, uuid, path, token, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken), data;

    if (token !== null) {
        data = {
            keyStoreUri: path.keyStoreUri,
            bearerToken: token
        }
    } else {
        if (path.keyStoreUri === undefined) {
            data = {
                keyStorePath: path.keyStorePath
            }
        } else {
            data = {
                keyStoreUri: path.keyStoreUri
            }
        }
    }

    requestUrl = url + "/ticket/" + uuid + "/keyStore";
      return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then((response) => {
          console.log(response);
          return response.json()
    });
}

export const verifyDSData = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("text", useToken)

    requestUrl = url + "/ticket/" + uuid + "/ds/verifier"
      return fetch(requestUrl, {
        method: 'POST',
        headers: header
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const getConnecteds = (url, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/token/connected"
      return fetch(requestUrl, {
        method: 'GET',
        headers: header
      }).then((response) => {
        return response.json()
    });
}

export const getKeysProfiles = (url, useToken) => (dispatch) => {
    var requestUrl, message, header = setHeders("json", useToken)

    requestUrl = url + "/generator/keysProfiles"
      return fetch(requestUrl, {
        method: 'GET',
        headers: header
      }).then((response) => {
        if(response.ok) {
          return response.json()
        } else {
          try {
              var jsonResponse = JSON.parse(response.responseText);
              message = jsonResponse.message;
          } catch (e) {
            return null
          }
          console.log(message)
          return null
        }
    });
}

export const generateNewKeys = (url, data) => (dispatch) => {
    var requestUrl, message, keyGenerationData, header = setHeders("json")

    keyGenerationData = data;

    requestUrl = url + "/generator/creator"
      return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(keyGenerationData)
      }).then((response) => {
        return response.json()
    });
}

export const generateNewVPNKeys = (url, data) => (dispatch) => {
    var requestUrl, keyGenerationData, header = setHeders("json")

    keyGenerationData = data;

    requestUrl = url + "/registrar"

    var timeout = function(ms, promise) {
      return new Promise(function(resolve, reject) {
        setTimeout(function() {
          reject(new Error("timeout"))
        }, ms)
        promise.then(resolve, reject)
      })
    }

    return timeout(90000, fetch(requestUrl, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(keyGenerationData)
        })).then((response) => {
        console.log(response)
        if (response.ok) {
            console.log(response);
            return response.json()
        } else {
            return response.json().then(json => {
              return {
                contentType: response.headers.get("Content-Type"),
                json: json
              }
            })
        }
    }).catch(function(error) {
        console.log('Request timed out')
        return null
    })
}

export const getVerifiedData = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/ds/verifier"
      return fetch(requestUrl, {
        method: 'GET',
        headers: header
      }).then((response) => {
        return response.json()
    });
}

export const getSignedData = (url, uuid, needToGetSignedData) => (dispatch) => {
    var requestUrl, header = setHeders("blob")

    if (needToGetSignedData !== undefined && needToGetSignedData === false) {
      return null
    }

    requestUrl = url + "/ticket/" + uuid + "/ds/signedData";
      return fetch(requestUrl, {
      method: 'GET',
      dataType: "blob",
      headers: header
    }).then((response) => {
      if(response.ok) {
        return response.blob()
      } else {
        return null
      }
    });
}

export const sendSessionMetaData = (url, uuid, metaData, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/metadata"
      return fetch(requestUrl, {
        method: 'put',
        headers: header,
        body: JSON.stringify({metaData: metaData})
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const createDS = (url, uuid, pass, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)
    requestUrl = url + "/ticket/" + uuid + "/ds/creator"

    return fetch(requestUrl, {
      method: 'POST',
      dataType: "json",
      headers: header,
      body: JSON.stringify({keyStorePassword: pass})
    }).then((response) => {
      response.json().then((response) => {
        console.log(response);
        return
      });
    });
}

export const getDSResult = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("blob", useToken)

    requestUrl = url + "/ticket/" + uuid + "/ds/data"
    return fetch(requestUrl, {
      method: 'GET',
      dataType: "blob",
      headers: header
    }).then((response) => {
      console.log(response)
      if (response.ok) {
        return response.blob()
      } else {
        return null
      }
    });
}

export const getDSStatus = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/ds/creator"
    return fetch(requestUrl, {
      method: 'GET',
      dataType: "json",
      headers: header
    }).then((response) => {
        console.log(response);
        return response.json()
    });
}

export const getSupportedCA = (url, useToken) => (dispatch) => {
    var header = setHeders("json", useToken)
    return fetch(url + "/certificateAuthority/supported", {
        method: 'GET',
        headers: header
      }).then((response) => {
          return response.json()
    });
}

export const sendKeyContainer = (url, uuid, keyContainer, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("blob", useToken)

    requestUrl = url + "/ticket/" + uuid + "/keyStore"

    return fetch(requestUrl, {
        method: 'PUT',
        headers: header,
        body: keyContainer
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const getKCInfo = (url, uuid, pass, useToken) => (dispatch) => {
    var requestUrl, message, failureCause, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/keyStore/verifier"

    return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify({"keyStorePassword": pass})
    }).then((response) => {
        if (response.ok) {
          message = (response.responseText === undefined) ? "Данные сертификата ключа ЕКЦ получены." : response.responseText;
          console.log(message)
          return response.json()
        } else {
          try {
              var jsonResponse = JSON.parse(response.responseText);
              message = jsonResponse.message;
              failureCause = jsonResponse.failureCause;
              console.log(failureCause)
          } catch (e) {
              message = (response.responseText === undefined) ? "Ошибка при проверке ключевого контейнера." : response.responseText;
              console.log(message)
          }
          return response.json()
        }
    });
}

export const getCertificateInfo = (url, uuid, pass) => (dispatch) => {
    var requestUrl, message, header = setHeders("json")

    requestUrl = url + "/ticket/" + uuid + "/keyStore/certificate/info/signature"

    return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify({"keyStorePassword": pass})
    }).then((response) => {
        if(response.ok) {
          message = (response.responseText === undefined) ? "Данные сертификата ключа ЕКЦ получены." : response.responseText;
          console.log(message)
          return response.json()
        } else {
          try {
              var jsonResponse = JSON.parse(response.responseText);
              message = jsonResponse.message;
          } catch (e) {
              message = (response.responseText === undefined) ? "Ошибка при получении дынных сертификата ЕКЦ." : response.responseText;
          }
          console.log(message)
          return null
        }
    });
}

export const getCertificateInfoKeyAgreement = (url, uuid, pass) => (dispatch) => {
    var requestUrl, message, header = setHeders("json")

    requestUrl = url + "/ticket/" + uuid + "/keyStore/certificate/info/keyAgreement"

    return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify({"keyStorePassword": pass})
    }).then((response) => {
        if(response.ok) {
          message = (response.responseText === undefined) ? "Данные сертификата ключа шифрования получены." : response.responseText;
          console.log(message)
          return response.json()
        } else {
          try {
              var jsonResponse = JSON.parse(response.responseText);
              message = jsonResponse.message;
          } catch (e) {
              message = (response.responseText === undefined) ? "Ошибка при получении дынных сертификата ключа шифрования." : response.responseText;
          }
          console.log(message)
          return null
        }
    });
}

export const sendCertificate = (url, uuid, data, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    var dataToSend = {
        recipientCertificates: [data]
    }

    requestUrl = url + "/ticket/" + uuid + "/encryptor/certificates"

    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(dataToSend)
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const encryptData = (url, uuid, pass, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/encryptor"

    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: JSON.stringify({keyStorePassword: pass})
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const encryptTextData = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/encryptor"

    return fetch(requestUrl, {
        method: 'GET',
        headers: header
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const getEncryptorStatus = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/encryptor"
    return fetch(requestUrl, {
      method: 'GET',
      dataType: "json",
      headers: header
    }).then((response) => {
        console.log(response);
        return response.json()
    });
}

export const getEncryptTextData = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/encryptor/base64Data"

    return fetch(requestUrl, {
        method: 'GET',
        headers: header
      }).then((response) => {
          console.log(response);
          return response.json()
    });
}

export const getDecryptTextData = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/decryptor/base64Data"

    return fetch(requestUrl, {
        method: 'GET',
        headers: header
      }).then((response) => {
          console.log(response);
          return response.json()
    });
}

export const decryptData = (url, uuid, pass, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/decryptor"

    return fetch(requestUrl, {
        method: 'POST',
        headers: header,
        body: JSON.stringify({keyStorePassword: pass})
      }).then((response) => {
        response.json().then((response) => {
          console.log(response);
          return
        });
    });
}

export const getDecryptorStatus = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/decryptor"
    return fetch(requestUrl, {
      method: 'GET',
      dataType: "json",
      headers: header
    }).then((response) => {
        console.log(response);
        return response.json()
    });
}

export const getCertificateInBase64 = (url, uuid, keyType) => (dispatch) => {
    var requestUrl, header = setHeders("json")
    requestUrl = url + "/ticket/" + uuid + "certificate/base64/" + keyType
    return fetch(requestUrl, {
      method: 'GET',
      dataType: "json",
      mode: "no-cors",
      headers: header
    }).then((response) => {
      if(response.ok) {
        return response.json()
      } else {
        return null
      }
    });
}

export const getCertificateDataInBase64 = (url, uuid, pass, keyType, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken), data;

    if (token !== null) {
        data = {
            keyStorePassword: pass,
            bearerToken: useToken
        }
    }

    requestUrl = url + "/ticket/" + uuid + "/keyStore/certificate/base64/" + keyType
    return fetch(requestUrl, {
      method: 'PUT',
      dataType: "json",
      headers: header,
      body: JSON.stringify(data)
    }).then((response) => {
      if(response.ok) {
        return response.json()
      } else {
        return null
      }
    });
}


export const getEncryptedData = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/encryptor/data"
    return fetch(requestUrl, {
      method: 'GET',
      dataType: "blob",
      headers: header
    }).then((response) => {
      console.log(response)
      if(response.ok) {
        return response.blob()
      } else {
        return null
      }
    });
}

export const getDecryptedData = (url, uuid, useToken) => (dispatch) => {
    var requestUrl, header = setHeders("json", useToken)

    requestUrl = url + "/ticket/" + uuid + "/decryptor/data"
    return fetch(requestUrl, {
      method: 'GET',
      dataType: "blob",
      headers: header
    }).then((response) => {
      console.log(response)
      if(response.ok) {
        return response.blob()
      } else {
        return null
      }
    });
}

export const deleteSession = (url, uuid, useToken) => (dispatch) => {
    var header = setHeders("json", useToken)
    return fetch(url + "/ticket/" + uuid, {
      method: "DELETE",
      headers: header,
      dataType: "json",
      cache: "no-cache",
    }).then((response) => {
        response.json().then((response) => {
            console.log(response);
            return
        });
    });
}

export const changeServiceLanguage = (url, lng) => (dispatch) => {
  var header = setHeders("json")
  console.log(lng);
    return fetch(url + "/language/" + lng, {
      method: "POST",
      headers: header,
      dataType: "json"
    }).then((response) => {
        response.json().then((response) => {
            console.log(response);
        });
    });
}

export const getNativeBuildsInfo = (url, token) => (dispatch) => {
  var header = setHeders("blob", token)
    return fetch(url + "/builds.info", {
        method: 'GET',
        dataType: "blob",
        headers: header
      }).then((response) => {
          return response.blob()
    });
}