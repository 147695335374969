import { combineReducers } from 'redux';
import localesReducer from './i18n'
// import dafaultState from './client';
// import activeProfile from './selectedProfile';
import verifyDSInputReducer from './setVerifyDSInput'
import selectedContainerData from './selectedContainerData'
// import keyContainers from './keyContainers';
import selectedContainer from './selectedContainer';
import verifyDSOptionsReducer from './verifyDSOptionsReducer'
import createDSOptionsReducer from './createDSOptionsReducer'
import encryptOptionsReducer from './encryptOptionsReducer'
import certificateInfo from './certificateInfo'
import createDSInputReducer from './createDSInputReducer'
import createDSTextReducer from './createDSTextReducer'
import encryptReducer from './encryptReducer'
import decryptReducer from './decryptReducer'
import privateKeyReducer from './privateKeyReducer'
import connectionStatusReucer from './connectionStatus'
import keyGeneratorReducer from './keyGeneratorReducer'
import verifyDSTextInputReducer from './verifyDSTextInputReducer'
import encryptTextReducer from './encryptTextReducer'
import decryptTextReducer from './decryptTextReducer'
import encryptFilesReducer from './encryptFilesReducer'
import mobileModeReducer from './mobileModeReducer'
import expandedCardsReducer from './expandedCardsReducer';
import upgradeDSReducer from './upgradeDSReducer'
import upgradeTextDSReducer from './upgradeTextDSReducer'
import vpnKeyGeneratorReducer from './vpnKeyGeneratorReducer'

const baseURLs = {
    urlsSetted: false,
    urlUCC: "",
    urlCloud: "",
    urlUCCAgent: "",
    cloudURLForMobileDS: "",
    version: "",
    build: "",
    defaultACSKId: "",
    token: "",
    qrCodeUrl: "",
    isLoading: false,
    showKeyGenTab: false,
    bodyIsMounted: false,
    vpnKeyGeneratorEnabled: "false",
    vpnKeyGeneratorUrl: "https://ra-vpn-dev-api.cipher.com.ua",
    globalProcessing: false,
    mobileIdEnabled: "false",
    cipherHSMEnabled: "false",
    depositSignEnabled: "false",
    vtcoEnabled: "false",
    clientId: "",
    vtcoURL: "",
    isVtco: false,
    numberOfPinInput: 3,
    pdfReportEnabled: "false",
    generateKeys: "true",
    vtcoList: null,
    handymanUrl: "",
    showVisualImpairmentView: false,
    contrast: false,
    fontSize: 0,
    expireTime: 0,
    isKerberos: false,
    selectedAuthProvider: null,
    authProvidersList: null,
    showErrorPopup: false,
    errorData: null,
    uosAgentNativeBuilds: []
}

const base = (state = baseURLs, { type, payload }) => {

    switch (type) {
        case 'SET_URLS': {
            return {
                ...state,
                urlUCC: payload.uccURL,
                urlCloud: payload.cloudURL,
                urlUCCAgent: payload.agentUccURL,
                defaultACSKId: payload.defaultACSKId,
                cloudURLForMobileDS: payload.cloudURL + "/api/v1",
                urlsSetted: true,
                token: payload.jwtToken,
                qrCodeUrl: payload.qrCodeUrl || "",
                vpnKeyGeneratorEnabled: payload.vpnKeyGeneratorEnabled || "false",
                vpnKeyGeneratorUrl: payload.vpnKeyGeneratorUrl || "https://ra-vpn-dev-api.cipher.com.ua",
                mobileIdEnabled: payload.mobileIdEnabled || "false",
                cipherHSMEnabled: payload.cipherHSMEnabled || "false",
                depositSignEnabled: payload.depositSignEnabled || "false",
                vtcoEnabled: payload.vtcoEnabled || "false",
                clientId: payload.clientId || "cihsmVtcoServiceClient",
                vtcoURL: payload.vtcoURL || "https://cihsm-dev-api.cipher.com.ua/vtco/api/v1",
                vtcoList: payload.vtcoURLsList || null,
                pdfReportEnabled: payload.pdfReportEnabled || "false",
                generateKeys: payload.generateKeys || "false",
                handymanUrl: payload.handymanUrl || payload.cloudURL + "/handyman/api/v1/ad/user",
                uosAgentNativeBuilds: payload.uosAgentNativeBuilds || []
            }
        }

        case 'SET_VERSION': {
            return {
                ...state,
                version: payload.version,
                build: payload.build
            }
        }

        case 'SET_IS_LOADING': {
            return {
                ...state,
                isLoading: payload
            }
        }

        case 'SET_SHOW_KEY_GEN_TAB': {
            return {
                ...state,
                showKeyGenTab: payload
            }
        }

        case 'SET_BODY_IS_MOUNTED': {
            return {
                ...state,
                bodyIsMounted: payload
            }
        }

        case 'SET_GLOBAL_PROCESSING': {
            return {
                ...state,
                globalProcessing: payload
            }
        }

        case 'SET_IS_VTCO': {
            return {
                ...state,
                isVtco: payload
            }
        }

        case 'SET_VTCO_URL': {
            return {
                ...state,
                vtcoURL: payload
            }
        }

        case 'SHOW_VISUAL_IMPAIRMENT_VIEW': {
            return {
                ...state,
                showVisualImpairmentView: payload
            }
        }

        case 'SET_CONTRAST': {
            return {
                ...state,
                contrast: payload
            }
        }

        case 'SET_FONTSIZE': {
            return {
                ...state,
                fontSize: payload
            }
        }

        case 'SET_IS_KERBEROS': {
            return {
                ...state,
                isKerberos: payload
            }
        }

        case 'SET_SELECTED_AUTH_PROVIDER': {
            return {
                ...state,
                selectedAuthProvider: payload
            }
        }

        case 'SET_AUTH_PROVIDERS_LIST': {
            return {
                ...state,
                authProvidersList: payload
            }
        }

        case "SET_EXPIRE_TIME": {
            return {
                ...state,
                expireTime: payload
            }
        }

        case 'SET_UOS_AGENT_NATIVE_BUILDS': {
            return {
                ...state,
                uosAgentNativeBuilds: payload
            }
        }

        case 'SET_SHOW_ERROR_POPUP': {
            return {
                ...state,
                showErrorPopup: payload
            }
        }

        case 'SET_ERROR_DATA': {
            return {
                ...state,
                errorData: payload
            }
        }

        default:
            return state;
    }

}

export default combineReducers({
    base: base,
    localesReducer: localesReducer,
    // dafaultState: dafaultState,
    // activeProfile: activeProfile,
    connectionStatusReucer: connectionStatusReucer,
    verifyDSInputReducer: verifyDSInputReducer,
    selectedContainer: selectedContainer,
    selectedContainerData: selectedContainerData,
    verifyDSOptionsReducer: verifyDSOptionsReducer,
    createDSOptionsReducer: createDSOptionsReducer,
    encryptOptionsReducer: encryptOptionsReducer,
    certificateInfo: certificateInfo,
    createDSInputReducer: createDSInputReducer,
    createDSTextReducer: createDSTextReducer,
    encryptReducer: encryptReducer,
    decryptReducer: decryptReducer,
    privateKeyReducer: privateKeyReducer,
    keyGeneratorReducer: keyGeneratorReducer,
    verifyDSTextInputReducer: verifyDSTextInputReducer,
    encryptTextReducer: encryptTextReducer,
    decryptTextReducer: decryptTextReducer,
    encryptFilesReducer: encryptFilesReducer,
    mobileModeReducer: mobileModeReducer,
    expandedCardsReducer: expandedCardsReducer,
    upgradeDSReducer: upgradeDSReducer,
    upgradeTextDSReducer: upgradeTextDSReducer,
    vpnKeyGeneratorReducer: vpnKeyGeneratorReducer
});


